<template>
  <notes-table
    :data="notes"
    :readonly="viewonly"
    @save="saveNote"
    @delete="deleteNote"
    title="Customer Notes"
    class="ma-0 pa-0"
  ></notes-table>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Util from './utility'
import NotesTable from '@/components/customer/NotesTable.vue'

export default {
  data() {
    return {
      
    }
  },
  computed: {
    ...mapGetters('customer', ['viewonly']),
    ...mapState('customer', {
      notes: state => state.notes
    })
  },
  methods: {
    ...mapActions('customer', {fetchNotes: 'fetchNotes', saveNoteStore: 'saveNote', deleteNoteStore: 'deleteNote'}),
    compareChanges: Util.compareChanges,
    copyObject: Util.copyObject,
    sortedNotes: Util.sortedNotes,
    fetchData() {
      this.fetchNotes()
    },
    saveNote(data) {
      const localId = data.id
      delete data.id
      const payload = {
        note: Object.assign({}, data),
        localId
      }
      this.saveNoteStore(payload).then(() => {
      }).catch(() => {
        alert('Saving failed!')
      })
    },
    deleteNote(data) {
      const localId = data.localId
      const note = data.note
      const payload = {
        note,
        localId
      }

      this.deleteNoteStore(payload).catch(() => {
        alert('Delete failed!')
      })
    }
  },
  watch: {
    '$route': {
      handler: function (to)  {
        const regex = /customers\/.+\/(edit|view)/
        if (regex.test(to.path)) {
          this.fetchData()
        } else {
          this.notes = []
        }
      },
      immediate: true
    }
  },
  components: {
    NotesTable
  }
}
</script>

<style scoped>
.bold {
  font-weight: bold;
}

.white-bg {
  background-color: white;
}
</style>