<template>
  <v-toolbar>
      <v-app-bar-nav-icon @click.stop="toggleMenu"></v-app-bar-nav-icon>
      <v-toolbar-title v-text="title"></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="logout" color="primary">Log Out</v-btn>
    </v-toolbar>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'

  export default {
    data () {
      return {
        title: 'Sensational Spas'
      }
    },
    computed: {
      ...mapState([
        'isAuth'
      ])
    },
    methods: {
      logout() {
        this.$store.commit('logout')
        this.$router.push('/login')
      },
      ...mapMutations('menu', [
        'toggleMenu'
      ])
    }
  }
</script>