const state = {
  drawer: false,
  items: [{
        icon: 'home',
        title: 'Home',
        path: '/'
      },
      {
        icon: 'group',
        title: 'Customers',
        path: '/customers'
      },
      {
        icon: 'library_add',
        title: 'Add Customer',
        path: '/customers/new'
      },
      {
        icon: 'import_contacts',
        title: 'Leads',
        path: '/leads'
      },
      {
        icon: 'contact_mail',
        title: 'Add Lead',
        path: '/leads/new'
      }
    ]
}

const getters = {

}

const mutations = {
  toggleMenu (state, p) {
    if (p) {
      state.drawer = p
    } else {
      state.drawer = !state.drawer
    }
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}