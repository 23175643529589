<template>
  <v-card>
    <v-layout class="pa-5">
      <v-flex xs10 offset-xs1>
        <v-form>
          <v-layout row class="py-5">
            <v-flex xs12>
              <h2>Edit User</h2>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12>
              <v-text-field v-model="name" label="Name"></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12>
              <v-text-field v-model="email" label="Email Address"></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12>
              <v-text-field
                v-model="password"
                :label="passwordLabel"
                :hint="passwordHint"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12>
              <v-select v-model="role" :items="roleChoices" label="Role" solo></v-select>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12 class="text-right">
              <v-btn text @click="cancel">Cancel</v-btn>
              <v-btn color="success" @click="save">Save</v-btn>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  props: {
    user: Object,
    new: Boolean
  },
  data() {
    return {
      localId: '',
      name: '',
      email: '',
      password: '',
      role: '',
      roleChoices: [
        {
          text: 'Administrator',
          value: 'admin'
        },
        {
          text: 'Viewer',
          value: 'viewer'
        }
      ]
    }
  },
  computed: {
    passwordLabel() {
      if (this.new) return 'Create Password'
      return 'Change Password'
    },
    passwordHint() {
      if (this.new) return ''
      return 'Leave blank for password to be unchanged'

    }
  },
  watch: {
    user: {
      handler() {
        const user = Object.assign({}, this.user)
        this.name = user.name
        this.email = user.email
        this.password = user.password
        this.role = user.role
        this.localId = user.localId
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    save() {
      const payload = {
        user: {
          name: this.name,
          email: this.email,
          password: this.password,
          role: this.role,
          id: this.localId,
        },
        isNew: this.new
      }
      this.$emit('saved', payload)
    },
    cancel() {
      this.$emit('canceled')
    }
  }
}
</script>

<style scoped>
</style>