import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'

// Modules
import menu from './modules/menu'
import customer from './modules/customer'
import card from './modules/card'

import Cookies from 'browser-cookies'

Vue.use(Vuex)

const API_SITE = process.env.VUE_APP_API_SITE


export default new Vuex.Store({
  state: {
    isAuth: false,
    token: '',
    nextPath: '/',
  },
  getters: {
    getToken(state) {
      return state.token
    },
    axiosConfig(state, getters, rootState) {
      return {
        baseURL: API_SITE,
        headers: {
          'Authorization': 'Bearer ' + rootState.token
        }
      }
    },
    getMode(state, getters, rootState) {
      const edit = /\/customers\/.+\/edit/
      const path = rootState.route.path
      if (path == "/customers/new") {
        return "new"
      } else if (edit.test(path)) {
        return "edit"
      } else {
        return "view"
      }
    },
    viewonly(state, getters) {
      return getters.getMode == 'view'
    }
  },
  mutations: {
    loggedIn(state, payload) {
      state.isAuth = true
      state.token = payload.token
    },
    logout(state) {
      state.token = ''
      Cookies.erase('token')
      state.isAuth = false
    },
    preLoginPath(state, payload) {
      state.nextPath = payload
    }
  },
  actions: {
    login({ commit }, payload) {
      return Axios.post(API_SITE + "/user/login", payload)
        .then(function (response) {
          return new Promise((resolve, reject) => {
            if (response.status !== 200) {
              reject("Error: Unable to authenticate")
            }
            if (response.data.jwt) {
              Cookies.set('token', response.data.jwt, { expires: 7 })
              commit('loggedIn', { token: response.data.jwt })
              resolve()
            } else {
              reject(response.data.message)
            }
          })
        })
        .catch(function () {
          return new Promise((resolve, reject) => {
            reject("Invalid Username/Password")
          })
        })
    },
    auth({ commit }, payload) {
      return Axios.post(API_SITE + "/user/auth", payload)
        .then(function (response) {
          return new Promise((resolve, reject) => {
            if (response.status !== 200) {
              Cookies.erase('token')
              reject("Error: Unable to authenticate")
            }

            if (response.data.jwt) {
              Cookies.set('token', response.data.jwt, { expires: 7 })
              commit('loggedIn', { token: response.data.jwt })
              resolve()
            } else {
              reject(response.data.message)
            }
          })
        })
        .catch(() => {
          return new Promise((res, rej) => {
            Cookies.erase('token')
            rej("Invalid Session Identifier")
          })
        })
    }
  },
  modules: {
    menu,
    customer,
    card
  }
})
