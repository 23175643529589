import Axios from 'axios'
import Vue from 'vue'

const state = {
  info: {
    id: '',
    firstName: '',
    lastName: '',
    spouse: '',
    address: '',
    address2: '',
    addressType: '',
    city: '',
    state: '',
    zip: '',
    email: '',
    phone: [{
      number: '',
      type: ''
    }],
    primaryPhone: 0
  },
  spas: [],
  pools: [],
  notes: [],
  isLead: false,
}

const getters = {
  axiosConfig(state, getters, rootState, rootGetters) {
    return rootGetters.axiosConfig
  },
  getMode(state, getters, rootState, rootGetters) {
    return rootGetters.getMode
  },
  viewonly(state, getters, rootState, rootGetters) {
    return rootGetters.viewonly
  }

}

const mutations = {
  SET_NOTES: (state, payload) => {
    Vue.set(state, 'notes', payload)
  },
  SET_NOTE: (state, payload) => {
    const localId = payload.localId
    if (typeof localId !== 'undefined') {
      state.notes.splice(localId, 1, payload.note)
    } else {
      state.notes.push(payload.note)
    }

  },
  DELETE_NOTE: (state, payload) => {
    const localId = payload.localId
    state.notes.splice(localId, 1)
  }
}

const actions = {
  fetchNotes({ commit, rootState, getters }) {
    return new Promise((resolve, reject) => {
      Axios.get('/customers/' + rootState.route.params.id + '/notes', getters.axiosConfig)
        .then(function (response) {
          commit('SET_NOTES', response.data.notes)
          resolve()
        })
        .catch(() => reject())
    })
  },
  saveNote({ commit, rootState, getters }, payload) {
    return new Promise((resolve, reject) => {
      const localId = payload.localId
      const mode = payload.note._id ? 'put' : 'post'
      const url = '/customers/' + rootState.route.params.id + '/notes' + (mode === 'put' ? '/' + payload.note._id : '')
      Axios[mode](url, payload, getters.axiosConfig)
        .then((res) => {
          if (res.data.status !== 'Success') reject('Unable to save note: ' + (res.data.error || res.data.error.message))
          commit('SET_NOTE', {note: res.data.note, localId})
          resolve()
        })
        .catch((err) => {
          reject('Unable to save: ' + err.message)
        })
    })
  },
  deleteNote({ commit, rootState, getters }, payload) {
    return new Promise((resolve, reject) => {
      const localId = payload.localId
      const url = '/customers/' + rootState.route.params.id + '/notes/' + payload.note._id
      Axios.delete(url, getters.axiosConfig)
        .then((res) => {
          if (res.data.status !== 'Success') reject('Unable to delete note: ' + (res.data.error || res.data.error.message))
          commit('DELETE_NOTE', {localId})
          resolve()
        })
        .catch((err) => {
          reject('Unable to delete: ' + err.message)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}