const state = {
  cards: []
}

const getters = {
  axiosConfig(state, getters, rootState, rootGetters) {
    return rootGetters.axiosConfig
  }
}

const mutations = {
  ADD_NEW_CARD: (state) => {
    state.cards.push({})
  },
  SET_CARD: (state, payload) => {
    const localId = payload.card.localId
    state.cards.splice(localId, 1, payload.card)
  },
  SET_CARDS: (state, payload) => {
    const cards = payload.cards.map((c) => {
      c.isDirty = false
      return c
    })
    state.cards = cards
  },
  DELETE_CARD: (state, payload) => {
    const localId = payload.card.localId
    state.cards.splice(localId, 1)
  }
}

const actions = {
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}