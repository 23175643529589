<template>
  <v-form ref="custInfoForm" lazy-validation>
    <v-progress-linear v-if="loading" class="ma-0" :indeterminate="true"></v-progress-linear>
    <v-container v-if="!loading" class="ma-0 pa-0">
      <v-layout row>
        <v-flex>
          <v-card>
            <v-card-title>
              <h3>Customer Information</h3>
              <v-spacer />
              <v-icon v-if="!viewonly" color="red" @click="deleteCustomer()">delete</v-icon>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col xs12>
                  <v-alert v-if="isDirty" color="warning"><v-icon>warning</v-icon>Unsaved Changes!</v-alert>
                </v-col>
              </v-row>
              <v-layout row wrap>
                <v-flex xs8 md6>
                  <v-text-field
                    v-model="customer.firstName"
                    label="First Name"
                    :rules="[required]"
                    :readonly="viewonly"
                  ></v-text-field>
                </v-flex>
                <v-flex xs8 md6>
                  <v-text-field
                    v-model="customer.lastName"
                    label="Last Name"
                    :rules="[required]"
                    :readonly="viewonly"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs8 md6>
                  <v-text-field
                    v-model="customer.spouse"
                    label="Spouse's Name"
                    :readonly="viewonly"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-row wrap>
                <v-col xs12>
                  <v-textarea solo label="General Notes" v-model="customer.keyNote"></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex>
          <v-card>
            <v-card-title>
              <h3>Contact Information</h3>
            </v-card-title>
            <v-card-text>
              <v-layout row>
                <v-flex xs6>
                  <v-text-field label="Email Address" v-model="customer.email" :readonly="viewonly"></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap v-for="(phone, i) in customer.phone" :key="i">
                <v-flex xs4>
                  <phone-number v-model="phone.number" :readonly="viewonly"></phone-number>
                </v-flex>
                <v-flex xs2 md2>
                  <v-select
                    :items="['Home', 'Work', 'Cell', 'Fax', 'Local', 'Pager', 'Other']"
                    label="Type"
                    v-model="phone.type"
                    :rules="[required]"
                    :readonly="viewonly"
                  ></v-select>
                </v-flex>
                <v-flex xs2>
                  <v-text-field label="Ext" v-model="phone.ext" :readonly="viewonly"></v-text-field>
                </v-flex>
                <v-flex xs2 hidden-md-and-up>
                  <v-switch
                    label
                    @change="changePrimaryPhone(i)"
                    :input-value="isPrimaryPhone(i)"
                    :readonly="viewonly || customer.phone.length === 1"
                  ></v-switch>
                </v-flex>
                <v-flex md2 hidden-sm-and-down>
                  <v-switch
                    label="Primary"
                    @change="changePrimaryPhone(i)"
                    :input-value="isPrimaryPhone(i)"
                    :readonly="viewonly || customer.phone.length === 1"
                  ></v-switch>
                </v-flex>
                <v-flex xs1 md1 class="pt-4" v-if="!isPrimaryPhone(i)">
                  <v-icon medium v-show="!viewonly" @click="removePhone(i)">delete</v-icon>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs8>
                  <v-btn v-show="!viewonly" @click="addPhone()" color="primary">Add Phone Number</v-btn>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex>
          <v-card>
            <v-card-title>
              <h3>Billing Address</h3>
            </v-card-title>
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs8 md6>
                  <v-text-field
                    v-model="customer.address"
                    label="Street Address"
                    :rules="[required]"
                    :readonly="viewonly"
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 md2>
                  <v-select
                    label="Type"
                    :items="['None', 'Apt', 'Suite', 'Lot', 'Other']"
                    :value="customer.addressType || 'None'"
                    @input="$set(customer, 'addressType', $event)"
                    :rules="[required]"
                    :readonly="viewonly"
                  ></v-select>
                </v-flex>
                <v-flex xs6 md4 v-if="customer.addressType && customer.addressType != 'None'">
                  <v-text-field
                    v-model="customer.address2"
                    :label="customer.addressType + ' #'"
                    :rules="[required]"
                    :readonly="viewonly"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs8 md6>
                  <v-text-field
                    v-model="customer.city"
                    label="City"
                    :rules="[required]"
                    :readonly="viewonly"
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 md2>
                  <v-autocomplete
                    v-model="customer.state"
                    :items="states"
                    label="State"
                    :rules="[required]"
                    :readonly="viewonly"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs4 md4>
                  <v-text-field
                    label="Zip Code"
                    v-model="customer.zip"
                    :rules="[required]"
                    :readonly="viewonly"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout justify-space-between row wrap v-if="!viewonly">
        <v-flex xs11>
          <v-alert v-if="result.message" v-text="result.message" :color="result.color"></v-alert>
        </v-flex>
        <v-flex xs1 class="text-xs-right">
          <v-btn color="success" @click="save()">Save</v-btn>
        </v-flex>
      </v-layout>
      <v-layout row></v-layout>
    </v-container>
  </v-form>
</template>

<script>
import Axios from 'axios'
import { mapGetters } from 'vuex'
import PhoneNumber from './phoneNumber'
import { stateAbbrList } from '@/static/states'

export default {
  components: {
    PhoneNumber
  },
  data() {
    return {
      customer: {},
      customerWatch: null,
      isDirty: true,
      states: stateAbbrList,
      loading: false,
      result: {}
    }
  },
  computed: {
    ...mapGetters(['axiosConfig', 'viewonly', 'getMode'])
  },
  methods: {
    addPhone() {
      if (!Array.isArray(this.customer.phone)) this.$set(this.customer, 'phone', [])
      this.customer.phone.push({ number: '', type: '' })
    },
    removePhone(index) {
      if (index < this.customer.primaryPhone) {
        this.customer.primaryPhone--
      }
      this.customer.phone.splice(index, 1);
    },
    changePrimaryPhone(newVal) {
      this.customer.primaryPhone = newVal
    },
    isPrimaryPhone(val) {
      return val == this.customer.primaryPhone
    },
    resetData() {
      this.$set(this, 'customer', {})
    },
    fetchData() {
      Axios.get('/customers/' + this.$route.params.id, this.axiosConfig)
        .then((response) => {
          if (response.data.hasOwnProperty('error')) {
            this.$router.push('/404')
          }
          let customer = response.data.data
          this.customer = customer
          this.isDirty = false
          this.customerWatch = this.$watch('customer', () => { this.isDirty = true }, { deep: true })
        }).catch(() => {
          this.$router.push('/404')
        })
    },
    save() {
      if (!this.$refs.custInfoForm.validate()) {
        this.result = { message: 'Cannot save an incomplete customer', color: 'error' }
        setTimeout(() => {
          this.result = {}
        }, 3000)
        return
      }

      const edit = this.getMode === 'edit'
      const url = '/customers' + (edit ? '/' + this.$route.params.id : '')
      const method = edit ? 'put' : 'post'
      Axios[method](url, { customer: this.customer }, this.axiosConfig)
        .then((res) => {
          if (this.customerWatch) {
            this.customerWatch()
          }
          this.customer = res.data.customer
          this.isDirty = false
          
          if (method === 'post') { 
            this.$router.push('/customers/' + res.data.customer._id + '/edit')
            return
          }
          this.customerWatch = this.$watch('customer', () => { this.isDirty = true }, { deep: true })

          this.result = {
            message: 'Saved!',
            color: 'success'
          }
        })
        .catch((err) => {
          this.result = {
            message: 'Unable to save: ' + err,
            color: 'error'
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.result = {}
          }, 3000)
        })
    },
    deleteCustomer() {
      const confirmation = confirm('Are you SURE you want to delete this customer and all spas associated with this customer?\n\nTHIS ACTION IS NOT REVERSABLE!')

      if (!confirmation) {
        return
      }

      const url = '/customers/' + this.$route.params.id
      Axios.delete(url, this.axiosConfig).then((res) => {
        if (res.data.status !== 'Success') {
          throw 'Unable to delete customer'
        }

        alert('Customer Deleted!\nSo long, sucker!')

        this.$router.push('/')
      }).catch(() => alert('Failed to delete customer!'))

    }
  },
  watch: {
    '$route': {
      handler: function (to) {
        const regex = /customers\/.*\/(edit|view)/
        if (regex.test(to.path)) {
          this.fetchData()
        } else {
          this.resetData()
        }
      },
      immediate: true
    },
    isDirty(newVal) {
      this.$emit('isDirty', newVal)
    }
  }
}
</script>

<style scoped>
</style>