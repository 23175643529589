import '@babel/polyfill'
import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import VueMask from 'v-mask'
import App from './App.vue'
import router from './router'
import store from './store'
import mixins from './mixins'
import { sync } from 'vuex-router-sync'
import dotenv from 'dotenv'
import Moment from 'moment-timezone'

dotenv.config()
Moment.tz.setDefault('UTC')

sync(store, router)

Vue.mixin(mixins)
Vue.use(VueMask)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
