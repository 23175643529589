<template>
  <v-text-field
    label="Phone Number"
    v-model="phoneNumber"
    v-mask="`(###) ###-####`"
    :rules="[required, phoneNumberRule]"
    required
    :readonly="readonly"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    readonly: [String, Boolean]
  },
  data() {
    return {
      number: this.value,
    }
  },
  computed: {
    phoneNumber: {
      get() {
        return this.number
      },
      set(val) {
        let rawNumber = val.replace(/\D/g, '')
        this.$emit('input', rawNumber)
        this.number = val
      }
    }
  }
}
</script>

<style>
</style>