<template>
    <router-view></router-view>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapState([
      'isAuth'
    ])
  },
}
</script>
