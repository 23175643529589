<template>
  <v-form ref="poolsForm">
    <v-layout row v-for="(pool, i) in pools" :key="i">
      <v-flex xs12>
        <v-card>
          <v-card-title>
            <h3>Pool</h3>
            <v-spacer></v-spacer>
            <v-icon color="red" @click="deletePool(i)" v-if="!viewonly">delete</v-icon>
          </v-card-title>
          <v-card-text>
              <v-row>
                <v-col xs12>
                  <v-alert v-if="isDirty[i]" color="warning"><v-icon>warning</v-icon>Unsaved Changes!</v-alert>
                </v-col>
              </v-row>
            <v-layout row>
              <v-flex xs6>
                <v-text-field label="Size" v-model="pool.size" :readonly="viewonly"></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field label="Heater" v-model="pool.heater" :readonly="viewonly"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-text-field label="Filter" v-model="pool.filter" :readonly="viewonly"></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field label="Pump" v-model="pool.pump" :readonly="viewonly"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-menu
                  ref="menu"
                  :value="openDatePicker(i)"
                  :return-value.sync="setPoolPurchaseDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="getPoolPurchaseDate(i)"
                      label="Purchase Date"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :value="getPoolPurchaseDate(i, true)"
                    @input="setPoolPurchaseDate($event, i)"
                    no-title
                    type="month"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
            <v-row>
              <v-col xs12>
                <v-textarea solo label="General Description" v-model="pool.keyNote"></v-textarea>
              </v-col>
            </v-row>
            <v-layout row>
              <v-flex xs12>
                <h4>Location</h4>
                <v-spacer></v-spacer>
                <v-checkbox
                  label="Same as Billing Address"
                  v-model="pool.useBillingAddress"
                  :readonly="viewonly"
                ></v-checkbox>
              </v-flex>
            </v-layout>
            <v-layout row v-if="!pool.useBillingAddress">
              <v-flex xs8 md6>
                <v-text-field label="Address" v-model="pool.address" :readonly="viewonly"></v-text-field>
              </v-flex>
              <v-flex xs2 md2>
                <v-select
                  label="Type"
                  :items="['None', 'Apt', 'Suite', 'Lot', 'Other']"
                  v-model="pool.addressType"
                  :readonly="viewonly"
                ></v-select>
              </v-flex>
              <v-flex xs6 md4 v-if="pool.type && pool.type != 'None'">
                <v-text-field :label="pool.type + '#'" v-model="pool.address2" :readonly="viewonly"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row v-if="!pool.useBillingAddress">
              <v-flex xs8 md6>
                <v-text-field label="City" v-model="pool.city" :readonly="viewonly"></v-text-field>
              </v-flex>
              <v-flex xs2 md2>
                <v-select label="State" v-model="pool.state" :items="states" :readonly="viewonly"></v-select>
              </v-flex>
              <v-flex xs4 md4>
                <v-text-field label="Zip Code" v-model="pool.zip" :readonly="viewonly"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs4>
                <v-text-field label="Gate Code" v-model="pool.gateCode" :readonly="viewonly"></v-text-field>
              </v-flex>
              <v-flex xs3 offset-xs4>
                <v-alert
                  v-if="typeof saveResult[i] !== 'undefined'"
                  :color="saveResult[i].color"
                  v-text="saveResult[i].message"
                ></v-alert>
              </v-flex>
              <v-flex xs1 class="text-right">
                <v-btn color="success" @click="savePool(pool, i)">Save Pool</v-btn>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs12>
                <notes-table
                  title="Pool Notes"
                  @save="savePoolNote($event, pool, i)"
                  @delete="deletePoolNote($event, pool, i)"
                  :data="pool.notes"
                  :readonly="viewonly"
                ></notes-table>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout v-if="pools.length == 0">
      <v-flex>
        <v-card>
          <v-card-title>
            <h3>No Pools Here</h3>
          </v-card-title>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12 class="text-xs-right" v-if="!viewonly">
        <v-btn color="primary" @click="addPool()">Add Pool</v-btn>
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import Axios from 'axios'
import { stateAbbrList } from '@/static/states'
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment-timezone'
import Util from './utility'
import NotesTable from '@/components/customer/NotesTable.vue'

export default {
  data() {
    return {
      pools: [],
      states: stateAbbrList,
      newNote: -1,
      newNoteTxt: '',
      datePicker: -1,
      menu: false,
      date: '',
      saveResult: [],
      isDirty: [],
      poolWatch: [],
    }
  },
  computed: {
    ...mapGetters(['axiosConfig', 'getMode', 'viewonly'])
  },
  methods: {
    ...mapActions('customer', {
      deletePoolNoteStore: 'deletePoolNote'
    }),
    copyObject: Util.copyObject,
    sortedNotes: Util.sortedNotes,
    moment: Moment,
    addNote(val) {
      this.newNoteTxt = ''
      this.newNote = val
    },
    getPoolYear(i) {
      if (!this.pools[i].year) return ''
      const date = Moment(this.pools[i].year)
      return (date.isValid() ? date.format('Y') : '')
    },
    setPoolYear(val, i) {
      if (!val) {
        this.pools[i].year = null
        return
      }
      this.pools[i].year = Moment(val, 'Y').toDate()
    },
    getPoolPurchaseDate(i, forPicker = false) {
      if (!this.pools[i].purchaseDate) return ''
      const date = Moment(this.pools[i].purchaseDate)
      if (forPicker)
        return (date.isValid() ? date.format('Y-M') : '')

      return (date.isValid() ? date.format('M/Y') : '')
    },
    setPoolPurchaseDate(val, i) {
      if (!val) {
        this.pools[i].purchaseDate = null
        return
      }
      this.pools[i].purchaseDate = Moment(val).toDate()
    },
    savePoolNote(note, pool, poolLocalId) {
      const payload = {
        localId: note.id,
        poolId: pool._id,
        poolLocalId: poolLocalId,
        note
      }
      const localId = payload.localId
      const mode = payload.note._id ? 'put' : 'post'
      const url = '/customers/' + this.$route.params.id + '/pools/' + payload.poolId + '/notes' + (mode === 'put' ? '/' + payload.note._id : '')
      Axios[mode](url, payload, this.axiosConfig)
        .then((res) => {
          if (res.data.status !== 'Success') {
            throw 'Failed saving'
          }
          if (this.poolWatch[poolLocalId]) {
            this.poolWatch[poolLocalId]()
          }
          this.$set(this.pools[poolLocalId].notes, localId, res.data.note)
          this.poolWatch.splice(poolLocalId, 1, this.makeWatcher(poolLocalId))
        })
        .catch(() => {
        })
    },
    deletePoolNote(item, pool, poolLocalId) {
      const note = item.note
      const localId = item.localId

      const payload = {
        localId,
        poolLocalId,
        poolId: pool._id,
        noteId: note._id
      }
      const url = '/customers/' + this.$route.params.id + '/pools/' + payload.poolId + '/notes/' + payload.noteId
      Axios.delete(url, this.axiosConfig)
        .then((res) => {
          if (res.data.status !== 'Success') {
            throw 'Failed to delete note!'
          }
          if (this.poolWatch[poolLocalId]) {
            this.poolWatch[poolLocalId]()
          }
          this.$delete(this.pools[poolLocalId].notes, localId)
          this.poolWatch.splice(poolLocalId, 1, this.makeWatcher(poolLocalId))

        })
        .catch(() => {
          alert('Failed to delete note!')
        })
    },
    deletePool(key) {
      const dialog = "Are you sure you want to delete this pool?"
      const confirmation = confirm(dialog)
      if (!confirmation) return

      const pool = this.pools.splice(key, 1)[0]

      if (!pool._id) return

      const url = '/customers/' + this.$route.params.id + '/pools/' + pool._id
      Axios.delete(url, this.axiosConfig)
        .then((res) => {
          if (res.data.status !== 'Success') {
            throw 'Failed to delete'
          }
          this.isDirty.splice(key, 1)
          if (this.poolWatch[key]) {
            this.poolWatch[key]()
          }
          this.poolWatch.splice(key, 1)
        })
        .catch(() => {
          this.pools.splice(key, 0, pool)
          this.$set(this, 'result', {
            message: 'Failed to delete pool!',
            color: 'error'
          })
        })
    },
    addPool() {
      this.pools.push({})
      this.isDirty.push(true)
      this.poolWatch.push(this.makeWatcher(this.pools.length - 1))
    },
    openDatePicker(val) {
      if (!this.viewonly && this.datePicker == val) {
        return true
      }

      return false
    },
    resetData() {
      this.$set(this, 'pools', [])
    },
    fetchData() {
      Axios.get('/customers/' + this.$route.params.id + '/pools', this.axiosConfig)
        .then((response) => {
          this.pools = response.data.data
          for (let i = 0; i < this.pools.length; i++) {
            this.isDirty.push(false)
            this.poolWatch.push(this.makeWatcher(i))
          }
        })
        .catch(() => {
          alert('Unable to fetch pools')
        })
    },
    savePool(pool, i) {
      const edit = !!pool._id
      const localId = i
      const url = '/customers/' + this.$route.params.id + '/pools' + (edit ? '/' + pool._id : '')
      const method = edit ? 'put' : 'post'
      Axios[method](url, { pool }, this.axiosConfig)
        .then((res) => {
          if (res.data.status !== 'Success') {
            throw 'Failed to save'
          }

          this.runWithoutWatchers(() => {
            this.$set(this.pools, localId, res.data.pool)
            this.$set(this.isDirty, localId, false)
            this.$set(this.saveResult, localId, {
              message: 'Saved!',
              color: 'success'
            })
          })
        })
        .catch(() => {
          this.$set(this.saveResult, localId, {
            message: 'Saving Failed',
            color: 'error'
          })
        })
        .finally(() => {
          setTimeout(() => {
            this.$set(this.saveResult, localId, {})
          }, 3000)
        })

    },
    makeWatcher(poolLocalId) {
      return this.$watch('pools.' + poolLocalId, () => {
        this.$set(this.isDirty, poolLocalId, true)
        this.$emit('isDirty', this.isDirty.some(v => v))
      }, { deep: true })
    },
    runWithoutWatchers(callback) {
      this.poolWatch.forEach((c) => c())
      this.$set(this, 'poolWatch', [])
      callback()
      const watchers = this.pools.map((v, i) => this.makeWatcher(i))
      this.$set(this, 'poolWatch', watchers)
    }
  },
  watch: {
    '$route': {
      handler: function (to) {
        const regex = /customers\/.+\/(edit|view)/
        if (regex.test(to.path)) {
          this.fetchData()
        } else {
          this.resetData()
        }
      },
      immediate: true,
      deep: true
    },
    'isDirty': {
      handler: function(newVal) {
        this.$emit('isDirty', newVal.some(v => v))
      }
    }
  },
  components: {
    NotesTable
  }
}
</script>

<style scoped>
.bold {
  font-weight: bold;
}
</style>