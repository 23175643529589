<template>
  <v-container class="pa-0 ma-0">
    <v-layout row>
      <v-flex xs12 class="pa-0 ma-0">
        <v-toolbar color="white">
          <h2>{{ title }}</h2>
          <v-spacer></v-spacer>
          <v-btn @click.stop="newItem()" color="primary" dark class="mb-2">New Note</v-btn>
          <v-dialog v-model="dialog" persistent max-width="500px" v-if="!readonly">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <v-row>
                    <v-flex>
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field :value="getDate()" label="Date" readonly v-on="on"></v-text-field>
                        </template>
                        <v-date-picker :value="getDateForPicker()" @input="setDate($event)"></v-date-picker>
                      </v-menu>
                    </v-flex>
                  </v-row>
                  <v-layout row>
                    <v-flex xs12>
                      <v-textarea solo auto-focus label="Message" v-model="editedItem.body"></v-textarea>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click.native="close">Cancel</v-btn>
                <v-btn color="primary" text @click.native="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12 class="ma-0 pa-0">
        <v-data-table
          :headers="headers"
          :items="data"
          :options.sync="dataTableOptions"
          :custom-sort="sortByDate"
          show-expand
          single-expand
          item-key="_id"
          must-sort
        >
          <template v-slot:item.date="{ item }">{{ item.date | formatDate }}</template>
          <template v-slot:item.body="{ item }">{{ item.body | truncate }}</template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="py-4 px-10">{{ item.body }}</td>
          </template>
          <template v-slot:item.actions="{ item }">
            <span class="justify-center layout px-0" v-if="!readonly">
              <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
              <v-icon small @click="deleteItem(item)">delete</v-icon>
            </span>
            <span class="justify-center layout px-0" v-else>
              <v-icon small>not_interested</v-icon>
            </span>
          </template>
          <template v-slot:no-data>
            <v-alert :value="true" icon="warning" color="warning">No Notes Here</v-alert>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Moment from 'moment-timezone'

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: 'Notes'
    }
  },
  data: () => ({
    dialog: false,
    menu: false,
    dataTableOptions: {
      descending: true,
      itemsPerPage: 10,
      page: 1,
      totalItems: 0,
      sortBy: ['date'],
      mustSort: true,
      sortDesc: ['date']
    },
    headers: [
      {
        text: 'Created',
        value: 'date',
        width: '20%'
      },
      { text: 'Note', value: 'body', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false, width: '10%' }
    ],
    editedIndex: -1,
    editedItem: {
      id: '',
      date: '',
      body: ''
    },
    defaultItem: {
      id: '',
      date: '',
      body: ''
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Note' : 'Edit Note'
    }
  },
  filters: {
    formatDate(date = false) {
      if (!date) {
        return ""
      }
      return Moment(date).format("MMM DD, YYYY")
    },
    truncate(str) {
      if (str.length > 40) {
        return str.substr(0, 40).trim() + '...'
      }
      return str
    }
  },
  methods: {
    getDate() {
      return (this.editedItem.date ? Moment(this.editedItem.date).format("MM/DD/YY") : '')
    },
    getDateForPicker() {
      return (this.editedItem.date ? Moment(this.editedItem.date).format("YYYY-MM-DD") : '')
    },
    setDate(date) {
      this.editedItem.date = Moment(date).format("YYYY-MM-DD")
      this.menu = false
    },
    newItem() {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedItem.date = Moment()
      this.dialog = true
    },
    editItem(item) {
      this.editedIndex = this.data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem(item) {
      const index = this.data.indexOf(item)
      const confirmation = confirm('Are you sure you want to delete this note?')
      if (confirmation)
        this.$emit('delete', {note: item, localId: index})
    },
    close() {
      this.dialog = false
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
    },
    save() {
      if (this.editedItem.note == '') {
        this.close()
        return
      }
      if (this.editedIndex > -1) {
        // Object.assign(this.data[this.editedIndex], this.editedItem)
        this.editedItem.id = this.editedIndex
      } else {
        // this.data.push(this.editedItem)
        this.editedItem.id = this.data.length
      }

      this.$emit('save', this.editedItem)
      this.close()
    },
    sortByDate(items, index, isDescending) {
      let order = isDescending[0] ? -1 : 1
      return items.sort(function (a, b) {
        if (isNaN(Date.parse(a[index])) && isNaN(Date.parse(b[index]))) {
          return 0
        } else if (isNaN(Date.parse(a[index]))) {
          return order * -1
        } else if (isNaN(Date.parse(b[index]))) {
          return order
        } else {
          return (Date.parse(a[index]) - Date.parse(b[index])) * order
        }
      })
    }
  }
}

</script>

<style>
</style>