<template>
  <v-form ref="spasForm">
    <v-layout row v-for="(spa, i) in spas" :key="i">
      <v-flex xs12>
        <v-card>
          <v-card-title>
            <h3>Spa</h3>
            <v-spacer></v-spacer>
            <v-icon color="red" @click="deleteSpa(i)" v-if="!viewonly">delete</v-icon>
          </v-card-title>
          <v-card-text>
              <v-row>
                <v-col xs12>
                  <v-alert v-if="isDirty[i]" color="warning"><v-icon>warning</v-icon>Unsaved Changes!</v-alert>
                </v-col>
              </v-row>
            <v-layout row>
              <v-flex xs12>
                <v-text-field label="Brand" v-model="spa.brand" :readonly="viewonly"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs12>
                <v-text-field label="Model" v-model="spa.model" :readonly="viewonly"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-text-field
                  label="Year"
                  :value="getSpaYear(i)"
                  @input="setSpaYear($event, i)"
                  :readonly="viewonly"
                  :rules="[validYearRule]"
                ></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field label="Serial #" v-model="spa.serial" :readonly="viewonly"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-text-field
                  label="Purchased From"
                  v-model="spa.purchasedFrom"
                  :readonly="viewonly"
                ></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-menu
                  ref="menu"
                  :value="openDatePicker(i)"
                  :return-value.sync="setSpaPurchaseDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="getSpaPurchaseDate(i)"
                      label="Purchase Date"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :value="getSpaPurchaseDate(i, true)"
                    @input="setSpaPurchaseDate($event, i)"
                    no-title
                    type="month"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
            <v-row>
              <v-col xs12>
                <v-textarea solo label="General Description" v-model="spa.keyNote"></v-textarea>
              </v-col>
            </v-row>
            <v-layout row>
              <v-flex xs12>
                <h4>Location</h4>
                <v-spacer></v-spacer>
                <v-checkbox
                  label="Same as Billing Address"
                  v-model="spa.useBillingAddress"
                  :readonly="viewonly"
                ></v-checkbox>
              </v-flex>
            </v-layout>
            <v-layout row v-if="!spa.useBillingAddress">
              <v-flex xs8 md6>
                <v-text-field label="Address" v-model="spa.address" :readonly="viewonly"></v-text-field>
              </v-flex>
              <v-flex xs2 md2>
                <v-select
                  label="Type"
                  :items="['None', 'Apt', 'Suite', 'Lot', 'Other']"
                  v-model="spa.addressType"
                  :readonly="viewonly"
                ></v-select>
              </v-flex>
              <v-flex xs6 md4 v-if="spa.type && spa.type != 'None'">
                <v-text-field :label="spa.type + '#'" v-model="spa.address2" :readonly="viewonly"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row v-if="!spa.useBillingAddress">
              <v-flex xs8 md6>
                <v-text-field label="City" v-model="spa.city" :readonly="viewonly"></v-text-field>
              </v-flex>
              <v-flex xs2 md2>
                <v-select label="State" v-model="spa.state" :items="states" :readonly="viewonly"></v-select>
              </v-flex>
              <v-flex xs4 md4>
                <v-text-field label="Zip Code" v-model="spa.zip" :readonly="viewonly"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs4>
                <v-text-field label="Gate Code" v-model="spa.gateCode" :readonly="viewonly"></v-text-field>
              </v-flex>
              <v-flex xs3 offset-xs4>
                <v-alert
                  v-if="typeof saveResult[i] !== 'undefined'"
                  :color="saveResult[i].color"
                  v-text="saveResult[i].message"
                ></v-alert>
              </v-flex>
              <v-flex xs1 class="text-right">
                <v-btn color="success" @click="saveSpa(spa, i)">Save Spa</v-btn>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs12>
                <notes-table
                  title="Spa Notes"
                  @save="saveSpaNote($event, spa, i)"
                  @delete="deleteSpaNote($event, spa, i)"
                  :data="spa.notes"
                  :readonly="viewonly"
                ></notes-table>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout v-if="spas.length == 0">
      <v-flex>
        <v-card>
          <v-card-title>
            <h3>No Spas Here</h3>
          </v-card-title>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12 class="text-xs-right" v-if="!viewonly">
        <v-btn color="primary" @click="addSpa()">Add Spa</v-btn>
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import Axios from 'axios'
import { stateAbbrList } from '@/static/states'
import { mapGetters, mapActions } from 'vuex'
import Moment from 'moment-timezone'
import Util from './utility'
import NotesTable from '@/components/customer/NotesTable.vue'

export default {
  data() {
    return {
      spas: [],
      states: stateAbbrList,
      newNote: -1,
      newNoteTxt: '',
      datePicker: -1,
      menu: false,
      date: '',
      saveResult: [],
      isDirty: [],
      spaWatch: [],
    }
  },
  computed: {
    ...mapGetters(['axiosConfig', 'getMode', 'viewonly'])
  },
  methods: {
    ...mapActions('customer', {
      deleteSpaNoteStore: 'deleteSpaNote'
    }),
    copyObject: Util.copyObject,
    sortedNotes: Util.sortedNotes,
    moment: Moment,
    addNote(val) {
      this.newNoteTxt = ''
      this.newNote = val
    },
    getSpaYear(i) {
      if (!this.spas[i].year) return ''
      const date = Moment(this.spas[i].year)
      return (date.isValid() ? date.format('Y') : '')
    },
    setSpaYear(val, i) {
      if (!val) {
        this.spas[i].year = null
        return
      }
      this.spas[i].year = Moment(val, 'Y').toDate()
    },
    getSpaPurchaseDate(i, forPicker = false) {
      if (!this.spas[i].purchaseDate) return ''
      const date = Moment(this.spas[i].purchaseDate)
      if (forPicker)
        return (date.isValid() ? date.format('Y-M') : '')

      return (date.isValid() ? date.format('M/Y') : '')
    },
    setSpaPurchaseDate(val, i) {
      if (!val) {
        this.spas[i].purchaseDate = null
        return
      }
      this.spas[i].purchaseDate = Moment(val).toDate()
    },
    saveSpaNote(note, spa, spaLocalId) {
      const payload = {
        localId: note.id,
        spaId: spa._id,
        spaLocalId: spaLocalId,
        note
      }
      const localId = payload.localId
      const mode = payload.note._id ? 'put' : 'post'
      const url = '/customers/' + this.$route.params.id + '/spas/' + payload.spaId + '/notes' + (mode === 'put' ? '/' + payload.note._id : '')
      Axios[mode](url, payload, this.axiosConfig)
        .then((res) => {
          if (res.data.status !== 'Success') {
            throw 'Failed saving'
          }
          if (this.spaWatch[spaLocalId]) {
            this.spaWatch[spaLocalId]()
          }
          this.$set(this.spas[spaLocalId].notes, localId, res.data.note)
          this.spaWatch.splice(spaLocalId, 1, this.makeWatcher(spaLocalId))
        })
        .catch(() => {
        })
    },
    deleteSpaNote(item, spa, spaLocalId) {
      const note = item.note
      const localId = item.localId

      const payload = {
        localId,
        spaLocalId,
        spaId: spa._id,
        noteId: note._id
      }
      const url = '/customers/' + this.$route.params.id + '/spas/' + payload.spaId + '/notes/' + payload.noteId
      Axios.delete(url, this.axiosConfig)
        .then((res) => {
          if (res.data.status !== 'Success') {
            throw 'Failed to delete note!'
          }
          if (this.spaWatch[spaLocalId]) {
            this.spaWatch[spaLocalId]()
          }
          this.$delete(this.spas[spaLocalId].notes, localId)
          this.spaWatch.splice(spaLocalId, 1, this.makeWatcher(spaLocalId))

        })
        .catch(() => {
          alert('Failed to delete note!')
        })
    },
    deleteSpa(key) {
      const dialog = "Are you sure you want to delete this spa?"
      const confirmation = confirm(dialog)
      if (!confirmation) return

      const spa = this.spas.splice(key, 1)[0]

      if (!spa._id) return

      const url = '/customers/' + this.$route.params.id + '/spas/' + spa._id
      Axios.delete(url, this.axiosConfig)
        .then((res) => {
          if (res.data.status !== 'Success') {
            throw 'Failed to delete'
          }
          this.isDirty.splice(key, 1)
          if (this.spaWatch[key]) {
            this.spaWatch[key]()
          }
          this.spaWatch.splice(key, 1)
        })
        .catch(() => {
          this.spas.splice(key, 0, spa)
          this.$set(this, 'result', {
            message: 'Failed to delete spa!',
            color: 'error'
          })
        })
    },
    addSpa() {
      this.spas.push({})
      this.isDirty.push(true)
      this.spaWatch.push(this.makeWatcher(this.spas.length - 1))
    },
    openDatePicker(val) {
      if (!this.viewonly && this.datePicker == val) {
        return true
      }

      return false
    },
    resetData() {
      this.$set(this, 'spas', [])
    },
    fetchData() {
      Axios.get('/customers/' + this.$route.params.id + '/spas', this.axiosConfig)
        .then((response) => {
          this.spas = response.data.data
          for (let i = 0; i < this.spas.length; i++) {
            this.isDirty.push(false)
            this.spaWatch.push(this.makeWatcher(i))
          }
        })
        .catch(() => {
          alert('Unable to fetch spas')
        })
    },
    saveSpa(spa, i) {
      const edit = !!spa._id
      const localId = i
      const url = '/customers/' + this.$route.params.id + '/spas/' + (edit ? '/' + spa._id : '')
      const method = edit ? 'put' : 'post'
      Axios[method](url, { spa }, this.axiosConfig)
        .then((res) => {
          if (res.data.status !== 'Success') {
            throw 'Failed to save'
          }

          this.runWithoutWatchers(() => {
            this.$set(this.spas, localId, res.data.spa)
            this.$set(this.isDirty, localId, false)
            this.$set(this.saveResult, localId, {
              message: 'Saved!',
              color: 'success'
            })
          })
        })
        .catch(() => {
          this.$set(this.saveResult, localId, {
            message: 'Saving Failed',
            color: 'error'
          })
        })
        .finally(() => {
          setTimeout(() => {
            this.$set(this.saveResult, localId, {})
          }, 3000)
        })

    },
    makeWatcher(spaLocalId) {
      return this.$watch('spas.' + spaLocalId, () => {
        this.$set(this.isDirty, spaLocalId, true)
        this.$emit('isDirty', this.isDirty.some(v => v))
      }, { deep: true })
    },
    runWithoutWatchers(callback) {
      this.spaWatch.forEach((c) => c())
      this.$set(this, 'spaWatch', [])
      callback()
      const watchers = this.spas.map((v, i) => this.makeWatcher(i))
      this.$set(this, 'spaWatch', watchers)
    }
  },
  watch: {
    '$route': {
      handler: function (to) {
        const regex = /customers\/.+\/(edit|view)/
        if (regex.test(to.path)) {
          this.fetchData()
        } else {
          this.resetData()
        }
      },
      immediate: true,
      deep: true
    },
    'isDirty': {
      handler: function(newVal) {
        this.$emit('isDirty', newVal.some(v => v))
      }
    }
  },
  components: {
    NotesTable
  }
}
</script>

<style scoped>
.bold {
  font-weight: bold;
}
</style>