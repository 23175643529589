<template>
  <v-form ref="cardForm" lazy-validation>
    <v-progress-linear class="ma-0" :indeterminate="true" v-if="loading"></v-progress-linear>
    <v-container class="ma-0 pa-0">
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              <h3>Card Information</h3>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col xs12>
                  <v-alert v-if="isDirty" color="warning">
                    <v-icon>warning</v-icon>Unsaved Changes!
                  </v-alert>
                </v-col>
              </v-row>
              <v-row v-for="(card, i) in cards" :key="i">
                <v-col xs3>
                  <v-text-field
                    v-model="card.number"
                    label="Card Number"
                    :type="hideCardNumber ? 'password' : 'text'"
                    :append-icon="hideCardNumber ? 'fa-eye' : 'fa-eye-slash'"
                    @click:append="hideCardNumber = !hideCardNumber"
                    :readonly="viewonly"
                    autocomplete="none"
                  ></v-text-field>
                </v-col>
                <v-col xs3 md2>
                  <v-text-field v-model="card.expiration" label="Expiration" :readonly="viewonly"></v-text-field>
                </v-col>
                <v-col xs3 md2>
                  <v-text-field v-model="card.ccv" label="CCV" :readonly="viewonly"></v-text-field>
                </v-col>
                <v-col xs3 md2>
                  <v-text-field
                    v-model="card.streetNumber"
                    label="House Number"
                    :readonly="viewonly"
                  ></v-text-field>
                </v-col>
                <v-col xs3 md2>
                  <v-text-field v-model="card.zip" label="Billing Zip Code" :readonly="viewonly"></v-text-field>
                </v-col>
                <v-icon @click="deleteCard(card, i)" class="pr-3">delete</v-icon>
              </v-row>
              <v-row>
                <v-col xs10>
                  <v-alert v-if="result.message" :color="result.color" v-text="result.message"></v-alert>
                </v-col>
                <v-col xs2 class="text-right">
                  <v-btn color="primary" @click="addCard()" class="mr-3">Add Card</v-btn>
                  <v-btn color="success" @click="saveCards()">Save Cards</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import Axios from 'axios'

export default {
  data() {
    return {
      cards: [],
      hideCardNumber: true,
      loading: true,
      result: {
        color: '',
        message: ''
      },
      isDirty: false,
      cardWatch: null
    }
  },
  computed: {
    ...mapGetters(['axiosConfig', 'getMode', 'viewonly']),
  },
  methods: {
    fetchCards() {
      Axios.get('/customers/' + this.$route.params.id + '/cards', this.axiosConfig)
        .then((response) => {
          if (response.data.status !== 'Success') {
            throw 'Error retrieving cards'
          }
          this.cards = response.data.cards
          this.loading = false
          this.cardWatch = this.makeWatcher()
          return
        })
        .catch((err) => {
          this.result.message = err.message || 'Unable to save cards'
          this.result.color = 'red'
        })
        .finally(() => {
          setTimeout(() => {
            this.result.message = ''
            this.result.color = ''
          }, 3000)
        })
    },
    addCard() {
      if (this.cards.length > 4) {
        this.result.message = "Maximum of 5 cards"
        this.result.color = 'error'
        setTimeout(() => {
          this.result.message = ''
          this.result.color = ''
        }, 3000)
        return
      }
      this.cards.push({})
    },
    saveCards() {
      this.cards.map((card, i) => {
        const customerId = this.$route.params.id
        const mode = (card._id ? 'put' : 'post')
        const url = '/customers/' + customerId + '/cards' + (mode === 'put' ? '/' + card._id : '')
        Axios[mode](url, { card }, this.axiosConfig)
          .then((res) => {
            if (res.data.status !== 'Success') {
              throw 'Save Unsuccessful'
            }
            if (this.cardWatch) {
              this.cardWatch()
            }
            this.cards.splice(i, 1, res.data.card)
            this.$set(this, 'result', {
              message: 'Saved!',
              color: 'success'
            })
            this.$emit('isDirty', false)
            this.isDirty = false
            this.cardWatch = this.makeWatcher()
          })
          .catch((err) => {
            this.$set(this, 'result', {
              message: err.message || err || 'Unable to save cards',
              color: 'error'
            })
          })
          .finally(() => {
            setTimeout(() => {
              this.$set(this, 'result', {})
            }, 3000)
          })
      })
    },
    deleteCard(card, localId) {
      const customerId = this.$route.params.id
      const url = '/customers/' + customerId + '/cards/' + card._id
      Axios.delete(url, this.axiosConfig)
        .then((res) => {
          if (res.data.status !== 'Success') {
            throw 'Unable to save card'
          }
          if (this.cardWatch) {
            this.cardWatch()
          }
          this.cards.splice(localId, 1)
          this.cardWatch = this.makeWatcher()
        })
        .catch((err) => {
          this.result.message = err.message || 'Unable to delete card'
          this.result.color = "error"
        })
        .finally(() => {
          setTimeout(() => {
            this.result.message = ''
            this.result.color = ''
          }, 3000)
        })
    },
    makeWatcher() {
      return this.$watch('cards', () => {
        this.$emit('isDirty', true)
        this.isDirty = true
      }, { deep: true })
    }
  },
  watch: {
    '$route': {
      handler: function (to) {
        const regex = /customers\/.+\/(edit|view)/
        if (regex.test(to.path)) {
          this.fetchCards()
        }
      },
      immediate: true
    }
  }
}
</script>