<template>
  <v-container grid-list-xs>
    <v-layout row>
      <v-flex xs12>
        <v-alert :value="showLeaveWarning" type="warning" transition="scale-transition">
          <v-layout row align-content-space-between fill-height>
            <v-flex>
              <div class="mt-3">You have unsaved changes. Are you sure you want to leave?</div>
            </v-flex>
            <v-flex align-content-end>
              <v-btn color="red" @click="$router.push(to)">Yes</v-btn>
              <v-btn color="green" @click="showLeaveWarning = false">No</v-btn>
            </v-flex>
          </v-layout>
        </v-alert>
        <v-toolbar>
          <v-toolbar-title>{{ action }} Customer</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon
            v-if="pageType == 'view'"
            @click="$router.push('/customers/' + $route.params.id + '/edit')"
          >edit</v-icon>
          <!-- <v-btn v-else @click="saveAll()" color="success">Save All</v-btn> -->
        </v-toolbar>
      </v-flex>
    </v-layout>
    <v-tabs v-model="active" grow show-arrows>
      <v-tab ripple>General Info</v-tab>
      <v-tab-item>
        <edit-customer @isDirty="anyDirty[0] = $event"></edit-customer>
      </v-tab-item>
      <v-tab ripple v-if="pageType !== 'new'">Card Info</v-tab>
      <v-tab-item>
        <edit-card @isDirty="anyDirty[1] = $event"></edit-card>
      </v-tab-item>
      <v-tab ripple v-if="pageType !== 'new'">Spas</v-tab>
      <v-tab-item>
        <edit-spas @isDirty="anyDirty[2] = $event"></edit-spas>
      </v-tab-item>
      <v-tab ripple v-if="pageType !== 'new'">Pools</v-tab>
      <v-tab-item>
        <edit-pools @isDirty="anyDirty[3] = $event"></edit-pools>
      </v-tab-item>
      <v-tab ripple v-if="pageType !== 'new'">Customer Notes</v-tab>
      <v-tab-item>
        <edit-notes v-if="pageType !== 'new'"></edit-notes>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

import EditCustomer from '@/components/customer/EditInfo.vue'
import EditNotes from '@/components/customer/EditNotes.vue'
import EditSpas from '@/components/customer/EditSpas.vue'
import EditPools from '@/components/customer/EditPools.vue'
import EditCard from '@/components/customer/EditCard.vue'

export default {
  data() {
    return {
      active: 0,
      showLeaveWarning: false,
      anyDirty: [false, false, false, false]
    }
  },
  components: {
    EditCustomer,
    EditNotes,
    EditSpas,
    EditPools,
    EditCard
  },
  computed: {
    ...mapGetters({
      pageType: 'getMode'
    }),
    action() {
      return this.pageType.charAt(0).toUpperCase() + this.pageType.substr(1)
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
  },

  beforeRouteLeave(to, from, next) {
    if (this.anyDirty.some(v => v)) {
      let answer = false
      answer = window.confirm('Do you really want to leave? You may have unsaved changes!')

      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }
}

</script>

<style scoped>
</style>