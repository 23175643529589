const methods = {
    required: v => !!v || 'Required',
    phoneNumberRule(v) {
      // const rule = /\d{10}/
      const maskedRule = /\(\d{3}\) \d{3}-\d{4}/
      return maskedRule.test(v) || 'Must be a valid 10 digit phone number'
    },
    validYearRule(v) {
      const maskedRule = /\d{4}/
      return !v || maskedRule.test(v) || 'Invalid Year'
    }

}

export default {
  methods
}