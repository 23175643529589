<template>
  <v-container>
    <v-layout row>
      <v-flex xs12>
        <v-alert v-if="result.message" :color="result.color" v-text="result.message"></v-alert>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12>
        <v-card>
          <v-card-title>
            <h2>Users</h2>
            <v-icon class="ml-5" @click="addUser">add_box</v-icon>
          </v-card-title>
        </v-card>
        <v-data-table :headers="headers" :items="dataTable" item-key="_id">
          <template
            v-slot:item.role="{ item }"
          >{{ item.role === 'admin' ? 'Administrator' : 'Viewer'}}</template>
          <template v-slot:item.actions="{ item }">
            <v-icon @click.stop="editUser(item)">edit</v-icon>
            <v-icon @click.stop="deleteUser(item)">delete</v-icon>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout justify="center">
      <v-dialog v-model="dialog" persistent>
        <edit-user-form :user="{...dialogUser, localId: dialogUser._id}" :new="dialogNewUser" @saved="saveUser($event)" @canceled="dialog = false"></edit-user-form>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import Axios from 'axios'
import EditUserForm from '@/components/EditUserForm.vue'

export default {
  components: {
    EditUserForm
  },
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Role', value: 'role' },
        { text: 'Actions', value: 'actions' }
      ],
      dataTable: [],
      dialog: false,
      dialogNewUser: false,
      dialogUser: {},
      result: {
        color: '',
        message: ''
      }
    }
  },
  methods: {
    fetchUsers() {
      const axiosOptions = this.$store.getters['customer/axiosConfig']
      Axios.get('/users', axiosOptions)
        .then((res) => {
          this.dataTable = res.data.data
        })
        .catch(() => {
          this.result.color = "danger"
          this.result.message = "Unable to fetch users. Try refreshing the page."
        })
    },
    addUser() {
      this.dialogUser = {role: 'viewer'}
      this.dialogNewUser = true
      this.dialog = true
    },
    editUser(item) {
      this.dialogUser = Object.assign({}, item)
      this.dialogNewUser = false
      this.dialog = true
    },
    saveUser(payload) {
      const user = payload.user
      const isNew = payload.isNew
      const url = '/users/' + (isNew ? '' : user.id)
      const mode = isNew ? 'post' : 'put'
      const axiosOptions = this.$store.getters['customer/axiosConfig']
      Axios[mode](url, user, axiosOptions)
        .then((res) => {
          if (res.data.status === 'Success') {
            this.result.color = "success"
            this.result.message = "User Saved!"
            return this.fetchUsers()
          }
          throw new Error('Didn\'t work')
        })
        .catch((err) => {
          this.result.color = "danger"
          this.result.message = "Failed to save user! " + err.message
        })
        .finally(() => {
          setTimeout(() => {
            this.result.message = ''
            this.result.color = ''
          }, 3000)
        })

      this.dialog = false
    },
    deleteUser(user) {
      let confirmation = confirm('Are you sure you want to delete ' + user.name + '?')
      if (!confirmation) return
      const axiosOptions = this.$store.getters['customer/axiosConfig']
      Axios.delete('/users/' + user._id, axiosOptions)
        .then((res) => {
          if (res.data.status == 'Success') {
            this.result.color = "success"
            this.result.message = "User Deleted!"
            return this.fetchUsers()
          }
          throw new Error('Unable to delete user')
        })
        .catch((err) => {
          this.result.color = "danger"
          this.result.message = "Failed to delete user! " + err.message
        })
        .finally(() => {
          setTimeout(() => {
            this.result.message = ''
            this.result.color = ''
          }, 3000)
        })
    }
  },
  mounted() {
    this.fetchUsers()
  }
}
</script>

<style>
</style>