<template>
  <v-container>
    <v-layout row>
      <v-flex xs12>
        <v-card>
          <v-card-title>
            <h2>{{ isLead ? 'Leads' : 'Customers' }}&nbsp;&nbsp;&nbsp;</h2>
            <v-icon @click="$router.push(address + '/new')">add_box</v-icon>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-card>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="dataTable"
          :options="dataTableOptions"
          show-expand
          single-expand
          hide-default-footer
          item-key="_id"
          class="elevation-1"
        >
          <template v-slot:item.name="{ item }">{{ item.firstName + ' ' + item.lastName }}</template>
          <template v-slot:item.spas_count="{ item }">
            <v-icon v-if="item.spas_count >= 1" color="green">fa-check-circle</v-icon>
            <v-icon v-else color="red">fa-times-circle</v-icon>
          </template>
          <template v-slot:item.pools_count="{ item}">
            <v-icon v-if="item.pools_count >= 1" color="green">fa-check-circle</v-icon>
            <v-icon v-else color="red">fa-times-circle</v-icon>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon @click="$router.push('/customers/' + item._id + '/edit')">edit</v-icon>
            <!-- <v-icon @click="$router.push('customers/' + item._id + '/print')">local_printshop</v-icon> -->
          </template>
          <template v-slot:item.phone="{ item }">
            <td>{{ (item.phone[0] ? item.phone[0].number : '') | phoneNumberFilter }}</td>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="py-4 px-10">
              <v-container>
                <v-layout row>
                  <v-flex xs4>
                    <b>Billing Address:</b>
                    {{ item.address }}
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs3>
                    <b>City:</b>
                    {{ item.city }}
                  </v-flex>
                  <v-flex xs2>
                    <b>State:</b>
                    {{ item.state }}
                  </v-flex>
                  <v-flex xs4>
                    <b>Zip:</b>
                    {{ item.zip }}
                  </v-flex>
                </v-layout>
              </v-container>
              <v-container v-for="spa in item.spas" :key="spa._id">
                <hr />
                <v-layout row>
                  <v-flex xs4>
                    <b>Spa Address:</b>
                    {{ spa.address }}
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs3>
                    <b>City:</b>
                    {{ spa.city }}
                  </v-flex>
                  <v-flex xs2>
                    <b>State:</b>
                    {{ spa.state }}
                  </v-flex>
                  <v-flex xs4>
                    <b>Zip:</b>
                    {{ spa.zip }}
                  </v-flex>
                </v-layout>
              </v-container>
            </td>
          </template>
        </v-data-table>
        <v-pagination v-model="dataTableOptions.page" :length="pages"></v-pagination>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Axios from 'axios'

export default {
  name: 'CustomerList',
  components: {
  },
  props: {
    isLead: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    address() {
      return this.isLead ? 'leads' : 'customers'
    },
    headers() {
      let headers = [
        { text: 'Name', value: 'name' },
        { text: 'Spouse', value: 'spouse' },
        { text: 'Phone Number', value: 'phone' },
        { text: 'Street Address', value: 'address' },
        { text: 'Spa', value: 'spas_count' },
        { text: 'Pool', value: 'pools_count' },
        { text: 'Actions', value: 'actions', sortable: false }
      ]

      if (this.isLead) {
        headers.splice(4, 2, { text: 'Last Contacted', value: 'last_contacted' })
      }

      return headers
    }
  },
  data() {
    return {
      search: '',
      loading: true,
      dataTableOptions: {
        page: 1,
        itemsPerPage: 25,
        totalItems: 0
      },
      dataTable: [],
      pages: 1
    }
  },
  watch: {
    '$route.path': {
      handler: function () {
        this.fetch()
      },
      immediate: true
    },
    'dataTableOptions.page': {
      handler: function () {
        this.fetch()
      },
      immediate: true
    },
    search: {
      handler(criteria) {
        if (criteria.length > 2 || criteria.length === 0)
          this.fetch()
      }
    }
  },
  methods: {
    fetch() {
      this.loading = true
      this.getData(this.dataTableOptions.page, this.search)
        .then(data => {
          this.loading = false
          this.dataTable = data.data
          this.total = data.total
          this.pages = data.last_page
          this.dataTableOptions.totalItems = data.total
        })
        .catch(() => {
        })
    },
    getData(pageNo, criteria = null) {
      return new Promise((resolve, reject) => {
        const config = Object.assign({}, this.$store.getters['customer/axiosConfig'], {
          params: {
            page: pageNo,
            rowsPerPage: this.dataTableOptions.itemsPerPage
          }
        })
        if (criteria !== '') {
          config.params.search = criteria
        }
        Axios.get('/' + this.address, config)
          .then(function (response) {
            resolve(response.data)
          }).catch(function (err) {
            reject(err)
          })
      })

    }
  }
}
</script>

<style scoped>
</style>